import validate from "/data/runners/five/_work/apps-monorepo/apps-monorepo/node_modules/.pnpm/nuxt@3.12.1_@opentelemetry+api@1.8.0_@types+node@20.16.5_eslint@8.57.0_rollup@4.21.2_typescript@5.4.5_vite@5.4.3/node_modules/nuxt/dist/pages/runtime/validate.js";
import auth_45global from "/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/middleware/auth.global.js";
import manifest_45route_45rule from "/data/runners/five/_work/apps-monorepo/apps-monorepo/node_modules/.pnpm/nuxt@3.12.1_@opentelemetry+api@1.8.0_@types+node@20.16.5_eslint@8.57.0_rollup@4.21.2_typescript@5.4.5_vite@5.4.3/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  auth_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "company-guard": () => import("/data/runners/five/_work/apps-monorepo/apps-monorepo/apps/pay/middleware/companyGuard.js")
}